* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
    font-family: "Roboto", serif;
    height: 100%;
    width: 100%;
    max-width: 1920px;
    overflow-x: hidden;
    color: #1E1E1E;
    background-color: #fafafa;
    font-size: 16px;
}

/*...................................header........................................*/
header {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(210, 210, 210);
    align-items: baseline;
}

/*Navbar menu*/

nav {
    width: 100%;
    padding: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.1);
}

.navbar {
    height: 9rem;
    display: flex;
    justify-content: right;
    align-items: center;
    width: 100%;
}

.nav-menu {
    display: flex;
    align-items: center;
    gap: 5rem;
    z-index: 5;
    list-style: none;
}

nav li {
    padding: 1rem;
}

nav li a {
    text-decoration: none;
    color: #1E1E1E;
    font-size: 1.6rem;
    white-space: nowrap;
}

.nav-item:hover {
    font-weight: 500;
}

#active {
    font-weight: 500;
}

/*Menu text for hamburgarmenu*/

/*Hamburgarmeny*/
.hamburger {
    display: none;
    cursor: pointer;
}

/*lines for hamburgar menu*/
.bar {
    display: block;
    width: 2.5rem;
    height: 0.3rem;
    margin: 0.5rem auto;
    transition: all 0.3s ease-in-out;
    background-color: #1E1E1E;
}

.menu-text {
    font-size: 2rem;
    color: #1E1E1E;
    position: absolute;
    right: 5rem;
    display: none;
    margin-left: 2rem;
}


/*logotype*/
.logotyp {
    padding: 2rem;
    max-width: fit-content;
    height: fit-content;
}

.logotyp img {
    max-width: 15rem;
    width: 100%;
    height: auto;
}

main {
    padding: 2rem;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
}

p {
    width: 100%;
    max-width: 55ch;
    font-size: 1.6rem;
    line-height: 150%;
}

.start-text {
    background-color: white;
    padding: 1rem;
    box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.1);
    line-height: 150%;
}

h1 {
    margin-bottom: 2rem;
    font-size: 4.2rem;
}

h2 {
    margin-bottom: 1rem;
    font-size: 3.2rem;
}

.list-cloudflare {
    margin: 2rem;
    max-width: 55ch;
    width: 100%;
}


/*......................................screen under 800px.......................................*/

@media screen and (max-width:800px) {

    /*......................................hamburger menu.......................................*/
    .hamburger {
        display: block;
        position: absolute;
        right: 2rem;
        z-index: 20;
    }

    .menu-text {
        display: block;
        font-weight: 600;
        z-index: 20;
    }


    /*Transform bars to cross*/
    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
        display: none;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(0.8rem) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(0) rotate(-45deg);
    }

    nav {
        height: 5rem;
    }

    .nav-mobil.active {
        position: fixed;
        top: 13rem;
    }

    .navbar {
        height: 0;
        align-items: center;
    }

    /*Navbar dropdown*/
    .nav-menu {
        position: fixed;
        right: 0;
        opacity: 0;
        top: 0;
        gap: 0;
        flex-direction: column;
        background-color: rgb(210, 210, 210);
        width: 100%;
        text-align: center;
        transition: 0.3s;
        height: 100%;
        justify-content: flex-start;
        padding-top: 20rem;
        z-index: 19;
        display: none;
        animation: 0.3s linear navAnimation;
    }

    @keyframes navAnimation {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .nav-item {
        display: inline;
        width: 100%;
    }

    nav li {
        padding: 3rem;
    }

    .nav-active {
        background-color: #ffffff;
        box-shadow: 0px 5px 10px -1px rgba(0, 0, 0, 0.1);
    }

    nav li a {
        font-size: 2.8rem;
    }

    /*active nav-page in menu*/
    .nav-menu.active {
        right: 0rem;
        opacity: 1;
        display: flex;
    }

    .nav-item:hover {
        border-bottom: 0;
        width: 100%;
        max-width: 100%;
    }
    
}