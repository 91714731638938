* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  color: #1e1e1e;
  background-color: #fafafa;
  width: 100%;
  max-width: 1920px;
  height: 100%;
  font-family: Roboto, serif;
  font-size: 16px;
  overflow-x: hidden;
}

header {
  background-color: #d2d2d2;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  height: 100%;
  display: flex;
}

nav {
  background-color: #fff;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 5px 10px -2px #0000001a;
}

.navbar {
  justify-content: right;
  align-items: center;
  width: 100%;
  height: 9rem;
  display: flex;
}

.nav-menu {
  z-index: 5;
  align-items: center;
  gap: 5rem;
  list-style: none;
  display: flex;
}

nav li {
  padding: 1rem;
}

nav li a {
  color: #1e1e1e;
  white-space: nowrap;
  font-size: 1.6rem;
  text-decoration: none;
}

.nav-item:hover, #active {
  font-weight: 500;
}

.hamburger {
  cursor: pointer;
  display: none;
}

.bar {
  background-color: #1e1e1e;
  width: 2.5rem;
  height: .3rem;
  margin: .5rem auto;
  transition: all .3s ease-in-out;
  display: block;
}

.menu-text {
  color: #1e1e1e;
  margin-left: 2rem;
  font-size: 2rem;
  display: none;
  position: absolute;
  right: 5rem;
}

.logotyp {
  max-width: fit-content;
  height: fit-content;
  padding: 2rem;
}

.logotyp img {
  width: 100%;
  max-width: 15rem;
  height: auto;
}

main {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

p {
  width: 100%;
  max-width: 55ch;
  font-size: 1.6rem;
  line-height: 150%;
}

.start-text {
  background-color: #fff;
  padding: 1rem;
  line-height: 150%;
  box-shadow: 0 5px 5px -2px #0000001a;
}

h1 {
  margin-bottom: 2rem;
  font-size: 4.2rem;
}

h2 {
  margin-bottom: 1rem;
  font-size: 3.2rem;
}

.list-cloudflare {
  width: 100%;
  max-width: 55ch;
  margin: 2rem;
}

@media screen and (width <= 800px) {
  .hamburger {
    z-index: 20;
    display: block;
    position: absolute;
    right: 2rem;
  }

  .menu-text {
    z-index: 20;
    font-weight: 600;
    display: block;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
    display: none;
  }

  .hamburger.active .bar:first-child {
    transform: translateY(.8rem)rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(0)rotate(-45deg);
  }

  nav {
    height: 5rem;
  }

  .nav-mobil.active {
    position: fixed;
    top: 13rem;
  }

  .navbar {
    align-items: center;
    height: 0;
  }

  .nav-menu {
    opacity: 0;
    text-align: center;
    z-index: 19;
    background-color: #d2d2d2;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0;
    width: 100%;
    height: 100%;
    padding-top: 20rem;
    transition: all .3s;
    animation: .3s linear navAnimation;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
  }

  @keyframes navAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .nav-item {
    width: 100%;
    display: inline;
  }

  nav li {
    padding: 3rem;
  }

  .nav-active {
    background-color: #fff;
    box-shadow: 0 5px 10px -1px #0000001a;
  }

  nav li a {
    font-size: 2.8rem;
  }

  .nav-menu.active {
    opacity: 1;
    display: flex;
    right: 0;
  }

  .nav-item:hover {
    border-bottom: 0;
    width: 100%;
    max-width: 100%;
  }
}
/*# sourceMappingURL=index.1096bb95.css.map */
